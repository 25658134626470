<template>
  <div v-if="student" class="student-selected show">
    <div class="shadow-bottom">
      <div class="selected-line">
        <div class="row">
          <div class="p-2 border-gradient-custom">
            <div class="icon-box icon-box-custom d-inline-block">
              <div>
                <em class="fa-stack text-green icon-circle">
                  <em class="fas fa-circle fa-stack-2x icon-circle-stack" />
                  <em class="fas fa-user fa-stack-1x fa-inverse text-yellow" />
                </em>
              </div>
            </div>
            <span class="label-user label-user-custom">
              {{ student.name }}
              <!-- {{ student.firstName }} {{ student.lastName }} -->
            </span>
          </div>
          <div class="p-2">
            <label class="d-inline label-user label-user-custom">SIE:</label>
            <span class="label-user-custom">{{ student.studentSieId }}</span>
          </div>
          <div class="action-button-container text-right">
            <em
              id="deselect-action-button"
              class="fa fa-times-circle mr-2"
              @click="$emit('clearStudentSelection')"
            />
            <a
              v-if="isCollapsed"
              aria-controls="ddlStudentCompactMenu"
              aria-expanded="false"
              data-toggle="collapse"
              href="#ddlStudentCompactMenu"
              role="button"
              @click="isCollapsed = !isCollapsed"
            >
              <em
                id="collapse-action-button"
                class="fas fa-chevron-square-up"
              />
            </a>
            <a
              v-if="!isCollapsed"
              aria-controls="ddlStudentCompactMenu"
              aria-expanded="false"
              data-toggle="collapse"
              href="#ddlStudentCompactMenu"
              role="button"
              @click="isCollapsed = !isCollapsed"
            >
              <em
                id="collapse-action-button"
                class="fas fa-chevron-square-down"
              />
            </a>
          </div>
        </div>
        <div id="ddlStudentCompactMenu" class="collapse">
          <div class="row student-additional-info">
            <div class="col-md-12">
              <label class="label-user-custom">Impedimento:</label>
              <span class="d-block label-user-span">
                {{
                  student.impediment != null
                    ? student.impediment.name
                    : 'No Disponible'
                }}
              </span>
            </div>
            <div class="col mt-2">
              <label class="label-user-custom">Registro:</label>
              <span class="label-user-span">
                {{
                  student.regStudentId != null
                    ? student.regStudentId
                    : 'No Disponible'
                }}
              </span>
            </div>
            <div class="col mt-2">
              <label class="label-user-custom">Estatus:</label>
              <span class="label-user-span">
                {{
                  student.status && student.status.name
                    ? student.status.name
                    : 'No Disponible'
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudentBannerMobile',
  data() {
    return {
      isCollapsed: false,
    };
  },
  props: {
    student: { type: Object, default: null },
  },
};
</script>

<style scoped>
.icon-box-custom {
  font-size: 14px;
}

.selected-line {
  border-bottom: 1px solid #f1d997 !important;
}

.icon-circle {
  width: 24px;
  height: 24px;
  border: 1px solid #2db782;
  border-radius: 100%;
  margin-right: 10px;
}

.icon-circle-stack {
  font-size: 18px;
  position: absolute;
  top: 2px;
  left: 0px;
}

.action-button-container {
  margin-top: 10px;
  position: absolute;
  right: 13px;
}

#search-action-button {
  color: #3f5b60;
  font-size: 18px;
  margin-right: 5px;
}
#deselect-action-button {
  border-radius: 50%;
  color: #c9545e;
  font-size: 18px;
  margin-right: 5px;
}
#collapse-action-button {
  color: #3f5b60;
  font-size: 18px;
}

.label-user-custom {
  font-size: 12px !important;
}
.label-user-span {
  font-size: 12px !important;
}

.border-gradient-custom {
  border-width: 0.09rem;
  border-right-style: solid;
  border-image: linear-gradient(
      to bottom,
      rgba(234, 206, 130, 1),
      rgba(255, 237, 187, 1)
    )
    1 100%;
}

.student-additional-info {
  margin-top: 10px !important;
  margin-left: 28px !important;
  padding-bottom: 10px !important;
}

.student-selected {
  min-height: 0px !important;
}

.student-selected .shadow-bottom {
  min-height: 0px !important;
}

#ddlStudentCompactMenu {
  position: absolute;
  z-index: 99;
  background: #ffedbb 0% 0% no-repeat padding-box;
  opacity: 1;
  left: 0px;
  width: 100%;
}
</style>
