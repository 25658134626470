<template>
  <div v-if="student">
    <div v-if="!isMobile" class="student-selected show">
      <div class="shadow-bottom">
        <div
          class="d-flex flex-row flex-wrap"
          style="margin-top:20px; !important;margin-left: 110px;"
        >
          <div class="col-md-3 border-gradient-right">
            <div class="d-flex align-items-center">
              <div class="icon-box mt-4">
                <div>
                  <i class="fa-stack text-green icon-circle">
                    <i class="fas fa-circle fa-stack-2x icon-circle-stack" />
                    <i class="fas fa-user fa-stack-1x fa-inverse text-yellow" />
                  </i>
                </div>
              </div>
              <div
                id="dvSelectedStudentName"
                class="label-user block-separator"
              >
                {{ student.name }}
              </div>
            </div>
          </div>
          <div class="col-md-1 block-separator">
            <label>SIE:</label>
            <span id="spSelectedStudentSie">{{ student.studentSieId }}</span>
          </div>
          <div class="col-md-2 border-gradient block-separator">
            <label>Registro:</label>
            <span>
              {{ student.regStudentId || 'No Disponible' }}
            </span>
          </div>
          <div class="col-md-3 block-separator">
            <label>Impedimento:</label>
            <span>
              {{ student.impediment.name || 'No Disponible' }}
            </span>
          </div>
          <div class="col-md-1 border-gradient-left block-separator">
            <label>Estatus:</label>
            <span>
              {{
                student.status && student.status.name
                  ? student.status.name
                  : 'No Disponible'
              }}
            </span>
          </div>
          <div class="col-md-2 block-separator text-center">
            <b-button variant="danger" @click.stop="clearStudentSelection">
              <i class="fa fa-times-circle" />
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <StudentBannerMobile
      v-if="isMobile"
      :student="student"
      @clearStudentSelection="clearStudentSelection"
    />
  </div>
</template>
<script>
import alert from '@/mixins/alert.mixin';
import store from '@/store/index';
import StudentBannerMobile from './StudentBannerMobile';

export default {
  components: {
    StudentBannerMobile,
  },
  props: { student: { type: Object, default: null } },
  methods: {
    async clearStudentSelection() {
      const response = await alert.methods.ShowCancelModal(
        'Confirmación',
        `¿Está seguro que desea cancelar la selección del estudiante ${this.student.name}?`,
        'Sí',
        'No'
      );
      if (response) {
        this.$router.push({ name: 'home' });
      }
    },
  },
  computed: {
    isMobile() {
      return store.getters.getIsMobileDensity;
    },
  },
};
</script>

<style scoped>
.icon-box {
  margin-top: 1px;
  margin-left: 5px;
  margin-right: 10px;
  font-size: 19px;
}

.col-block {
  padding-left: 40px;
}

.col-block label {
  font: Bold 16px/7px Lato;
  letter-spacing: 0;
  color: rgba(17, 41, 27, 1);
  margin-bottom: 6px;
}

.block-separator {
  padding-top: 20px;
}

.icon-circle {
  width: 40px;
  height: 40px;
  border: 1px solid #2db782;
  border-radius: 100%;
  line-height: 1.8em !important;
}

.icon-circle-stack {
  font-size: 32px;
  position: absolute;
  top: 3px;
  left: 0px;
}
</style>
