<template>
  <div v-if="studentSieId">
    <Portal to="sub-header">
      <StudentBanner ref="SelectedStudent" :student="student" />
    </Portal>

    <router-view />
  </div>
</template>

<script>
import StudentBanner from '@/components/StudentBanner';
import { parentModule } from '@/store/modules';

export default {
  name: 'StudentBannerWrapper',
  components: {
    StudentBanner,
  },
  props: { studentSieId: { type: [String, Number], default: null } },
  computed: {
    ...parentModule.mapGetters(['getStudentById']),
    student() {
      return this.getStudentById(this.studentSieId);
    },
  },
};
</script>
